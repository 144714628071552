import React from 'react';
import './App.css';
import { Route, Routes, BrowserRouter } from "react-router-dom";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Home from "./components/Home";
import AboutUs from "./components/AboutUs";
import ContactUs from "./components/ContactUs";
import Registration from "./components/Registration";
import RegistrationNew from "./components/RegistrationNew";
import PrintForm from "./components/PrintForm";
import Courses from './components/Courses';
import RegistrationSuccess from './components/RegistrationSuccess';
import { ReactComponent as CompanyIcon } from './assets/logo1.svg';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import 'react-whatsapp-widget/dist/index.css';

function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/AboutUs" element={<AboutUs />} />
        <Route path="/ContactUs" element={<ContactUs />} />
        <Route path="/Registration" element={<Registration />} />
        <Route path="/RegistrationNew" element={<RegistrationNew />} />
        <Route path="/PrintForm" element={<PrintForm />} />
        <Route path="/Courses" element={<Courses />} />
        <Route path="/RegistrationSuccess" element={<RegistrationSuccess />} />
      </Routes>
      {/* Conditional rendering of Footer */}
      {window.location.pathname !== '/PrintForm' && <Footer />}
      <div className="elfsight-app-b1eb5c65-4830-4cc1-9e77-e65e6cdaa192">
        <i className="fa fa-whatsapp whatsapp-icon"></i>
      </div>
    </BrowserRouter>
  );
}

export default App;
